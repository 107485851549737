<template>
  <v-app id="inspire">
    <!-- Left Menu Of Streams -->
    <v-navigation-drawer v-model="drawer" app justify-center>
      <v-list>
        <v-list-item-group v-model="leftMenuActive">
          <a>
            <v-img
              class="mb-4"
              :src="require('@/assets/images/logos/templogo.png')"
              max-height="120px"
              max-width="2200px"
              alt="logo"
              contain
              @click="getDetials(0, 'No Stream Selected!')"
            ></v-img>
          </a>
          <v-divider></v-divider>
          <v-list-item v-for="(s, i) in streams" :key="i.id" color="primary" link @click="getDetials(s.id, s.name)">
            <v-list-item-icon>
              <v-icon>mdi-animation-play</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ s.name }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
      <v-btn v-if="engineeringModOn" fixed left bottom class="" fab color="primary" @click="addNew = true">
        <v-icon large> mdi-plus </v-icon>
      </v-btn>
    </v-navigation-drawer>
    <!-- End Left Menu Of Streams -->
    <!-- Top App Drawer On/Off Button And Logout  -->
    <v-app-bar app>
      <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>
      <v-row>
        <v-col cols="10">
          <v-toolbar-title flaot left> Multistreamer </v-toolbar-title>
        </v-col>
        <v-col cols="2">
          <v-toolbar-items>
            <v-icon id="skullIcon" class="mx-5" float left color="white" @click="engineeringModDialog = true">
              mdi-skull-crossbones-outline
            </v-icon>
            <v-btn
              v-if="adminUser"
              float
              right
              class="mx-2"
              icon
              color="primary"
              @click="
                showUsers = true
                getUsers()
              "
            >
              <v-icon large> mdi-account-group </v-icon>
            </v-btn>
            <v-icon class="mx-5" float right @click="logOut"> mdi-logout </v-icon>
          </v-toolbar-items>
        </v-col>
      </v-row>
    </v-app-bar>
    <!-- End Top App Drawer On/Off Button And Logout -->
    <!-- Working Area -->
    <v-main style="padding: 30px 15px 15px 20px">
      <!--New Stream Dialog -->
      <v-dialog v-model="addNew" width="700px" height="500px">
        <v-card class="mx-auto" max-width="700px">
          <v-card-title>Create a new source</v-card-title>
          <v-card-text class="text--primary">
            <div>please enter a name for your new stream</div>
            <v-text-field
              ref="newStreamName"
              v-model="newStreamName"
              dense
              :style="{ margin: '25px 350px 0px 0px' }"
              name="newStreamName"
              label="Stream Name*"
              type="text"
            ></v-text-field>
            <v-select
              ref="newStreamType"
              v-model="newStreamType"
              :items="streamTypesList"
              label="Type*"
              dense
              outlined
              :style="{ margin: '10px 350px 0px 0px' }"
            ></v-select>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              :disabled="newStreamName === ''"
              @click="createNewStream(newStreamName, newStreamType)"
            >
              Add
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn
              color="secondary"
              text
              @click="
                addNew = false
                streamName = ''
              "
            >
              Cancel
            </v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- End New Stream Dialog -->
      <!--New users managment Dialog -->
      <v-dialog v-model="showUsers" width="75vw" :scrollable="true" class="d-flex align-self-center justify-center">
        <v-responsive class="mx-auto" width="55vw">
          <v-card class="mx-auto">
            <v-data-table
              :loading="usersTableLoading"
              loading-text="Loading... Please wait"
              :headers="usersHeaders"
              :items="usersList"
              :items-per-page="10"
              class="elevation-1"
            >
              <!-- Table Title & Table Dialogs -->
              <template v-slot:top>
                <v-toolbar flat>
                  <v-toolbar-title>Users Managment</v-toolbar-title>
                  <v-divider class="mx-4" inset vertical></v-divider>
                  <v-spacer></v-spacer>
                  <!-- User Create & Edit Dialog -->
                  <v-dialog v-model="dialogCreateEditUser" max-width="50%">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn color="primary" dark class="mb-2 mx-5" v-bind="attrs" v-on="on">
                        <v-icon large left> mdi-account-plus </v-icon>
                      </v-btn>
                      <v-btn icon color="primary" class="mb-2 mx-5" @click="getUsers()">
                        <v-icon> mdi-refresh </v-icon>
                      </v-btn>
                    </template>
                    <v-card>
                      <v-card-title>
                        <div class="text-h5 primary--text">
                          {{ userFormTitle }}
                        </div>
                      </v-card-title>
                      <v-card-subtitle>
                        <div class="error--text">All Fields Are Required</div>
                      </v-card-subtitle>
                      <v-card-text>
                        <v-container>
                          <v-row align="center" justify="center">
                            <v-col cols="5" sm="12" md="5">
                              <v-row>
                                <v-text-field
                                  v-model="editedUser.username"
                                  dense
                                  :disabled="userFormTitle === 'Edit User'"
                                  label="User Name"
                                  outlined
                                ></v-text-field>
                              </v-row>
                              <v-row>
                                <v-text-field
                                  v-model="editedUser.password"
                                  dense
                                  type="password"
                                  label="User Password"
                                  outlined
                                ></v-text-field>
                              </v-row>
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-card-text>

                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                          color="secondary"
                          @click="
                            editedUserIndex = -1
                            editedUser = defaultUser
                            dialogCreateEditUser = false
                          "
                        >
                          Cancel
                        </v-btn>
                        <v-spacer></v-spacer>

                        <v-btn
                          color="primary darken-1"
                          :disabled="editedUser.username === '' || editedUser.password === ''"
                          @click="saveUser"
                        >
                          Save
                        </v-btn>
                        <v-spacer></v-spacer>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                  <!-- End ReStream Create & Edit Dialog -->
                  <!-- ReStream Delete Dialog -->
                  <v-dialog v-model="dialogDelete" max-width="50%">
                    <v-card>
                      <v-card-title class=""> Are you sure you want to delete this restream? </v-card-title>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="primary ma-3" text @click="closeDelete"> Cancel </v-btn>
                        <v-spacer></v-spacer>
                        <v-btn depressed color="error ma-3" @click="deleteItemConfirm"> Confirm! </v-btn>
                        <v-spacer></v-spacer>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                  <!-- End ReStream Delete Dialog -->
                </v-toolbar>
              </template>
              <!-- End Table Title & Table Dialogs -->
              <template v-slot:[`item.password`]="{ item }">
                <v-text-field
                  :append-icon="item.showPass ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="item.showPass ? 'text' : 'password'"
                  :value="item.password"
                  readonly
                  single-line
                  class="input-group--focused"
                  @click:append="showUser(item)"
                ></v-text-field>
              </template>
              <!-- Table Action Column Style -->
              <template v-slot:[`item.actions`]="{ item }">
                <v-icon color="primary" medium class="mr-3" @click="editUser(item)"> mdi-pencil </v-icon>
                <!-- <v-icon color="primary" medium class="mr-4" @click="showUser(item)"> mdi-eye </v-icon> -->
                <v-icon color="error" medium class="ml-3" @click="deleteUser(item)"> mdi-delete </v-icon>
              </template>
              <!-- End Table Action Column Style -->
            </v-data-table>
          </v-card>
        </v-responsive>
      </v-dialog>
      <!-- End users managment Dialog -->
      <!-- Stream Detilas Secion -->
      <v-row>
        <!-- Stream Name&UUID + ReStreams Table -->
        <v-col cols="8">
          <!-- Stream Name&UUID -->
          <v-card v-if="BodyTitle.name !== 'No Stream Selected!'">
            <v-row>
              <v-col cols="10">
                <v-layout justify-left>
                  <v-card-title>
                    Stream:&nbsp;
                    <span class="primary--text"> {{ BodyTitle.name }} </span>
                  </v-card-title>
                </v-layout>
                <v-card-subtitle class="mb-3">
                  Stream Key: <span class="black--text font-weight-bold">{{ BodyTitle.id }}</span>
                </v-card-subtitle>
              </v-col>
            </v-row>
            <v-card-text>Manage destinations here</v-card-text>
          </v-card>
          <!-- End Stream Name&UUID -->
          <!-- ReStreams Table -->
          <v-card v-if="BodyTitle.name !== 'No Stream Selected!'">
            <v-data-table :headers="headers" :items="publishPlatforms" class="elevation-1">
              <!-- Table Title & Table Dialogs -->
              <template v-slot:top>
                <v-toolbar flat>
                  <v-toolbar-title>Publishing Destinations</v-toolbar-title>
                  <v-divider class="mx-4" inset vertical></v-divider>
                  <v-spacer></v-spacer>
                  <!-- ReStream Detials Show -->
                  <v-dialog v-model="dialogShow" max-width="50%">
                    <v-card>
                      <v-card-title>
                        <span class="text-h5 primary--text">Destination Details</span>
                      </v-card-title>
                      <v-card-text>
                        <v-container>
                          <v-row>
                            <v-col cols="6" sm="12" md="6">
                              <v-text-field
                                v-model="editedItem.name"
                                dense
                                disabled
                                label="Name"
                                outlined
                              ></v-text-field>
                            </v-col>
                            <v-col cols="6" sm="12" md="6">
                              <v-text-field
                                v-model="editedItem.destKey"
                                dense
                                disabled
                                label="Stream Key"
                                outlined
                              ></v-text-field>
                            </v-col>
                          </v-row>
                          <v-row>
                            <v-col cols="5" sm="12" md="5">
                              <v-text-field
                                v-model="editedItem.destHost"
                                dense
                                disabled
                                label="RTMP URL (e.g. a.rtmp.youtube.com)"
                                outlined
                              ></v-text-field>
                            </v-col>
                            <v-col cols="2" sm="12" md="2">
                              <v-text-field
                                v-model="editedItem.destPort"
                                dense
                                disabled
                                label="Port"
                                outlined
                              ></v-text-field>
                            </v-col>
                            <v-col cols="3" sm="6" md="3">
                              <v-text-field
                                v-model="editedItem.destApp"
                                dense
                                disabled
                                label="RTMP app"
                                outlined
                              ></v-text-field>
                            </v-col>
                            <v-col cols="2" sm="6" md="2">
                              <v-switch
                                v-model="editedItem.ssl"
                                dense
                                disabled
                                :false-value="false"
                                :true-value="true"
                                label="RTMPS"
                              ></v-switch>
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-card-text>

                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                          color="primary"
                          @click="
                            dialogShow = false
                            close()
                          "
                        >
                          Close
                        </v-btn>
                        <v-spacer></v-spacer>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                  <!-- End ReStream Detials Show -->
                  <!-- ReStream Create & Edit Dialog -->
                  <v-dialog v-model="dialog" max-width="50%">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn color="primary" dark class="mb-2 mx-5" v-bind="attrs" v-on="on">
                        <v-icon left> mdi-publish </v-icon>
                        <v-icon> mdi-plus </v-icon>
                      </v-btn>
                      <v-btn icon color="primary" class="mb-2 mx-5" @click="getDetials(BodyTitle.id, BodyTitle.name)">
                        <v-icon> mdi-refresh </v-icon>
                      </v-btn>
                    </template>
                    <v-card>
                      <v-card-title>
                        <div class="text-h5 primary--text">
                          {{ formTitle }}
                        </div>
                      </v-card-title>
                      <v-card-subtitle>
                        <div class="error--text">All Fields Are Required</div>
                      </v-card-subtitle>
                      <v-card-text>
                        <v-container>
                          <v-row align="center" justify="center">
                            <v-col cols="6" sm="12" md="6">
                              <v-select
                                v-model="selectedReStreamPlatform"
                                color="primary"
                                :items="reStreamsParamters"
                                item-text="name"
                                item-value="arguments"
                                item-avatar="icon"
                                label="Choose platform"
                                dense
                                outlined
                                return-object
                                @change="fillReStreamParams()"
                              >
                                <template #item="{ item }">
                                  <v-icon color="secondary">
                                    {{ item.icon }}
                                  </v-icon>
                                  <span class="ml-1 primary--text">{{ item.name }}</span>
                                </template>
                                <template #selection="{ item }">
                                  <v-icon color="secondary">
                                    {{ item.icon }}
                                  </v-icon>
                                  <span class="ml-1 primary--text">{{ item.name }}</span>
                                </template>
                              </v-select>
                            </v-col>
                          </v-row>
                          <v-row v-if="selectedReStreamPlatform.name !== ''" align="center" justify="center">
                            <v-col cols="5" sm="12" md="5">
                              <v-row>
                                <v-text-field v-model="editedItem.name" dense label="Name" outlined></v-text-field>
                              </v-row>
                              <v-row v-if="selectedReStreamPlatform.name === 'Custom RTMP'">
                                <v-text-field
                                  v-model="customStreamURL"
                                  :rules="[
                                    v =>
                                      /rtmp(s?):\/\/([\w\d\-\_\.]+):?(\d+)?\/([\w\d\-\_\.]+)(?:\/([\w\d\-\_\.]+))?/.test(
                                        v,
                                      ) || 'invalid url!',
                                  ]"
                                  dense
                                  :disabled="selectedReStreamPlatform.name != 'Custom RTMP'"
                                  label="RTMP URL"
                                  class="my-1"
                                  outlined
                                  hint="(e.g. rtmp://host.com/app)"
                                  @change="fillEditedItem"
                                ></v-text-field>
                              </v-row>
                              <v-row>
                                <v-text-field
                                  v-model="editedItem.reStreamKey"
                                  dense
                                  label="Stream Key"
                                  outlined
                                ></v-text-field>
                              </v-row>
                            </v-col>
                          </v-row>
                          <v-row v-if="selectedReStreamPlatform.name === 'Err!'">
                            <v-col cols="6" sm="12" md="6">
                              <v-text-field
                                v-model="editedItem.reStreamUrl"
                                dense
                                :disabled="selectedReStreamPlatform.name != 'Err!'"
                                label="RTMP URL"
                                outlined
                              ></v-text-field>
                            </v-col>
                            <v-col cols="3" sm="6" md="3">
                              <v-text-field
                                v-model="editedItem.app"
                                dense
                                :disabled="selectedReStreamPlatform.name != 'Err!'"
                                label="app"
                                outlined
                              ></v-text-field>
                            </v-col>
                            <v-col cols="3" sm="6" md="3">
                              <v-switch
                                v-model="editedItem.rtmps"
                                dense
                                :disabled="selectedReStreamPlatform.name != 'Err!'"
                                :false-value="false"
                                :true-value="true"
                                label="RTMPS"
                              ></v-switch>
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-card-text>

                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="secondary" @click="close"> Cancel </v-btn>
                        <v-spacer></v-spacer>

                        <v-btn
                          color="primary darken-1"
                          :disabled="editedItem.reStreamKey === '' || editedItem.name === ''"
                          @click="save"
                        >
                          Save
                        </v-btn>
                        <v-spacer></v-spacer>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                  <!-- End ReStream Create & Edit Dialog -->
                  <!-- ReStream Delete Dialog -->
                  <v-dialog v-model="dialogDelete" max-width="50%">
                    <v-card>
                      <v-card-title class=""> Are you sure you want to delete this restream? </v-card-title>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="primary ma-3" text @click="closeDelete"> Cancel </v-btn>
                        <v-spacer></v-spacer>
                        <v-btn depressed color="error ma-3" @click="deleteItemConfirm"> Confirm! </v-btn>
                        <v-spacer></v-spacer>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                  <!-- End ReStream Delete Dialog -->
                </v-toolbar>
              </template>
              <!-- End Table Title & Table Dialogs -->
              <!-- Name Column Style -->
              <template v-slot:[`item.name`]="{ item }">
                <div class="text-truncate" style="max-width: 400px">
                  {{ item.name }}
                </div>
              </template>
              <!-- End Name Column Style -->
              <!-- ReStream URL Column Style -->
              <!-- <template v-slot:[`item.destHost`]="{ item }">
                <div class="text-truncate" style="max-width: 200px">
                  {{ item.destHost }}
                </div>
              </template> -->
              <!-- End ReStream URL Column Style -->
              <!-- ReStream Key Column Style -->
              <!-- <template v-slot:[`item.destKey`]="{ item }">
                <div class="text-truncate" style="max-width: 200px">
                  {{ item.destKey }}
                </div>
              </template> -->
              <!-- End ReStream Key Column Style -->
              <!-- Table Action Column Style -->
              <template v-slot:[`item.actions`]="{ item }">
                <!-- <v-icon medium class="mr-4" disabled @click="editItem(item)"> mdi-pencil </v-icon> -->
                <v-icon color="primary" medium class="mr-4" @click="showItem(item)"> mdi-eye </v-icon>
                <v-tooltip bottom :disabled="item.status === false">
                  <template v-slot:activator="{ on }">
                    <div class="d-inline-block" v-on="on">
                      <v-icon color="error" medium :disabled="item.status === true" @click="deleteItem(item)">
                        mdi-delete
                      </v-icon>
                    </div>
                  </template>
                  <span>Turn Off First!</span>
                </v-tooltip>
              </template>
              <template v-slot:[`item.status`]="{ item }">
                <v-switch
                  v-model="item.status"
                  :true-value="true"
                  :false-value="false"
                  @change="isLive(item)"
                ></v-switch>
              </template>
              <!-- End Table Action Column Style -->
              <!-- Stats Column Style -->
              <template v-slot:[`item.stats`]="{ item }">
                <div v-if="item.stats" class="primary--text">
                  <span v-if="item.stats.state !== 'disconnected' && item.status === true">{{ item.stats.state }}</span>
                  <span v-if="item.status === false"> disconnected</span>
                  <span v-if="item.stats.state === 'disconnected' && item.stats.retry_count && item.status === true">
                    Retrying {{ item.stats.retry_count }}</span
                  >
                  <span v-if="item.stats.bandwidth"> / {{ (item.stats.bandwidth / 1048576).toFixed(2) }} Mbps </span>
                </div>
              </template>
              <!-- End Stats Column Style -->
            </v-data-table>
          </v-card>
          <!-- End ReStreams Table -->
        </v-col>
        <!-- End Stream Name&UUID + ReStreams Table -->
        <!-- Stream Live Preview and Delete -->
        <v-col cols="4">
          <v-card v-if="BodyTitle.name !== 'No Stream Selected!'">
            <v-row>
              <v-col cols="12">
                <v-layout justify-center>
                  <video-player
                    v-if="videoOptions.sources[0].src !== ''"
                    ref="streamPRV"
                    :key="videoOptions.sources[0].src"
                    :options="videoOptions"
                  />
                </v-layout>
                <v-list>
                  <v-list-group :value="true">
                    <template v-slot:activator>
                      <v-list-item-content>
                        <v-list-item-title>Stream Source Setup</v-list-item-title>
                      </v-list-item-content>
                    </template>
                    <v-list-item-content>
                      <v-tabs>
                        <v-tab>
                          <v-card-subtitle>RTMP</v-card-subtitle>
                        </v-tab>
                        <v-tab-item>
                          <v-card>
                            <v-card-text>
                              <v-text-field :value="BodyTitle.serverURL" label="URL" filled readonly></v-text-field>
                              <v-text-field
                                :value="BodyTitle.streamKey"
                                label="Stream Key"
                                filled
                                readonly
                              ></v-text-field>
                            </v-card-text>
                          </v-card>
                        </v-tab-item>
                        <!-- <v-tab>
                          <v-card-subtitle>SRT </v-card-subtitle>
                        </v-tab>
                        <v-tab-item>
                          <v-card>
                            <v-card-text>Not Ready Yet! </v-card-text>
                          </v-card>
                        </v-tab-item> -->
                      </v-tabs>
                    </v-list-item-content>
                  </v-list-group>
                  <v-list-group>
                    <template v-slot:activator>
                      <v-list-item-content>
                        <v-list-item-title>Playback</v-list-item-title>
                      </v-list-item-content>
                    </template>
                    <v-list-item-content>
                      <v-tabs>
                        <v-tab>
                          <v-card-subtitle>Video+Audio </v-card-subtitle>
                        </v-tab>
                        <v-tab-item>
                          <v-card>
                            <v-card-text>
                              <v-text-field
                                :value="sourceLivePreviewURL"
                                label="Hls url"
                                filled
                                readonly
                              ></v-text-field>
                            </v-card-text>
                          </v-card>
                        </v-tab-item>
                        <!-- <v-tab>
                          <v-card-subtitle>Audio </v-card-subtitle>
                        </v-tab>
                        <v-tab-item>
                          <v-card>
                            <v-card-text>
                              <v-text-field
                                :value="sourceLivePreviewURL"
                                label="Hls url"
                                filled
                                readonly
                              ></v-text-field>
                            </v-card-text>
                          </v-card>
                        </v-tab-item> -->
                      </v-tabs>
                    </v-list-item-content>
                  </v-list-group>
                </v-list>
                <!-- Delete Stream Button & Delete Stream Dialog -->
                <v-divider></v-divider>
                <v-layout justify-center>
                  <v-card-actions>
                    <v-tooltip bottom :disabled="publishPlatforms.length == 0">
                      <template v-slot:activator="{ on }">
                        <div class="d-inline-block" v-on="on">
                          <v-btn
                            v-if="engineeringModOn"
                            elevation="3"
                            class="ma-3"
                            color="error"
                            :disabled="publishPlatforms.length != 0"
                            @click="dialogDeleteStream = true"
                          >
                            <v-icon right dark> mdi-delete </v-icon>
                            Delete
                          </v-btn>
                        </div>
                      </template>
                      <span>Stop and delete destinations first!</span>
                    </v-tooltip>
                  </v-card-actions>
                  <v-dialog v-model="dialogDeleteStream" max-width="50%">
                    <v-card>
                      <v-card-title class=""> Are you sure you want to delete this stream? </v-card-title>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="primary ma-3" text @click="closeDeleteStream"> Cancel </v-btn>
                        <v-spacer></v-spacer>
                        <v-btn depressed color="error ma-3" @click="deleteStream(BodyTitle.id)"> Confirm! </v-btn>
                        <v-spacer></v-spacer>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </v-layout>
                <!-- End Delete Stream Button & Delete Stream Dialog -->
              </v-col>
            </v-row>
          </v-card>
        </v-col>
        <!-- End Stream Live Preview and Delete -->

        <v-dialog v-model="engineeringModDialog" max-width="50%">
          <v-card>
            <v-card-title>
              <div class="ma-2 text-h2 text-center error--text">DANGER ZONE</div>
            </v-card-title>
            <v-card-text class="mt-3">
              <div class="ma-2 text-h6 primary--text">
                <strong
                  >Engineering mode allows engineers to edit platform setup. Only enable if you definitely know what
                  you’re doing. If you don’t, and enable it anyway, everything will break, it will be your fault, shame
                  will follow you and your family for 6 generations.</strong
                >
                <br />
                <p class="my-3 error--text text-sm-body-2"></p>
              </div>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn x-large color="primary ma-3" @click="engineeringModDialog = false"> Stay Safe </v-btn>
              <v-spacer></v-spacer>
              <drag-verify
                :width="450"
                :height="60"
                :text="'Slide if you have a death wish'"
                :success-text="'Say your shahaada'"
                :background="'secondary'"
                :progress-bar-bg="'#f00'"
                :completed-bg="'#f00'"
                :handler-bg="'#fff'"
                :text-size="'21px'"
                :circle="true"
                @passcallback="engineeringActivate()"
              ></drag-verify>
              <!-- <v-btn
                depressed
                color="error ma-3"
                @click="
                  engineeringModOn = true
                  engineeringModDialog = false
                "
              >
                Confirm!
              </v-btn> -->
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <template>
          <div class="text-center">
            <v-snackbar v-model="snackbar" centered width="50%" color="error" :timeout="timeout">
              <p class="white--text text-h6">
                {{ snackTitle }}
              </p>
              <p class="white--text text-subtitle-2">
                {{ snackMessage }}
              </p>

              <template v-slot:action="{ attrs }">
                <v-btn color="white" text v-bind="attrs" @click="snackbar = false"> Close </v-btn>
              </template>
            </v-snackbar>
          </div>
        </template>
      </v-row>
      <!-- End Stream Detilas Secion -->
    </v-main>
    <!-- End Working Area -->
  </v-app>
</template>

<script>
import dragVerify from 'vue-drag-verify'
import VideoPlayer from './vidCompnent.vue'

export default {
  components: {
    dragVerify,
    VideoPlayer,
  },
  data: () => ({
    videoOptions: {
      autoplay: true,
      muted: true,
      controls: true,
      sources: [
        {
          src: '',
          type: 'application/x-mpegURL',
        },
      ],
    },
    drawer: null,
    addNew: false,
    showUsers: false,
    leftMenuActive: -1,
    BodyTitle: {
      id: 0,
      name: 'No Stream Selected!',
      serverURL: 'vmclouds.co.uk',
      streamKey: 'Not-Avilabel!',
    },
    newStreamName: '',
    newStreamType: '',
    streamTypesList: ['rtmp', 'srt'],
    sourceLivePreviewURL: '',
    livePreviewPlaying: false,
    LivePreviewChanged: false,
    LivePreviewPlayed: false,
    snackTitle: 'Request Failed',
    snackMessage: 'This should show error message',
    snackbar: false,
    timeout: 3500,
    streams: [],
    publishPlatforms: [],
    selectedReStreamPlatform: { name: '' },
    reStreamsParamters: [
      {
        name: 'YouTube',
        arguments: {
          type: 'rtmp',
          app: 'live2',
          host: 'a.rtmp.youtube.com',
          port: 1935,
          rtmps: false,
        },
        icon: 'mdi-youtube',
      },
      {
        name: 'Facebook',
        arguments: {
          type: 'rtmp',
          app: 'rtmp',
          host: 'live-api-s.facebook.com',
          port: 443,
          rtmps: true,
        },
        icon: 'mdi-facebook',
      },
      {
        name: 'Instagram',
        arguments: {
          type: 'rtmp',
          app: 'rtmp',
          host: 'live-upload.instagram.com',
          port: 443,
          rtmps: true,
        },
        icon: 'mdi-instagram',
      },
      {
        name: 'Custom RTMP',
        arguments: {
          type: 'rtmp',
          app: '',
          host: '',
          rtmps: false,
        },
        icon: 'mdi-web',
      },
    ],
    dialog: false,
    dialogCreateEditUser: false,
    dialogShow: false,
    dialogDelete: false,
    dialogDeleteStream: false,
    engineeringModDialog: false,
    adminUser: false,
    engineeringModOn: false,
    headers: [
      {
        text: 'Label',
        align: 'left',
        sortable: false,
        fixed: true,
        value: 'name',
      },
      {
        text: 'Push',
        align: 'left',
        sortable: false,
        fixed: true,
        value: 'status',
      },
      {
        text: 'Stats/Bitrate',
        value: 'stats',
        sortable: false,
      },

      // {
      //   text: 'ReStream URL',
      //   align: 'left',
      //   sortable: false,
      //   value: 'destHost',
      // },
      // {
      //   text: 'ReStream Key',
      //   align: 'left',
      //   sortable: false,
      //   value: 'destKey',
      // },
      {
        text: 'Actions',
        value: 'actions',
        sortable: false,
      },
    ],
    usersTableLoading: true,
    showPass: false,
    usersHeaders: [
      {
        text: 'User Name',
        align: 'left',
        sortable: false,
        fixed: true,
        width: '35%',
        value: 'username',
      },
      {
        text: 'Password',
        align: 'left',
        sortable: false,
        fixed: true,
        width: '35%',
        value: 'password',
      },
      {
        text: 'Actions',
        value: 'actions',
        width: '30%',
        sortable: false,
      },
    ],
    usersList: [],
    customStreamURL: '',
    editedIndex: -1,
    editedItem: {
      name: '',
      reStreamUrl: '',
      reStreamKey: '',
      port: 0,
      app: '',
      rtmps: false,
    },
    defaultItem: {
      name: '',
      reStreamUrl: '',
      reStreamKey: '',
      port: 0,
      app: '',
      rtmps: false,
    },
    editedUserIndex: -1,
    editedUser: {
      username: '',
      password: '',
    },
    defaultUser: {
      username: '',
      password: '',
    },
  }),
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? 'New Destination' : 'Edit Destination'
    },
    userFormTitle() {
      return this.editedUserIndex === -1 ? 'New User' : 'Edit User'
    },
  },

  watch: {
    dialog(val) {
      return val || this.close()
    },
    dialogDelete(val) {
      return val || this.closeDelete()
    },
    dialogDeleteStream(val) {
      return val || this.closeDeleteStream()
    },
    dialogCreateEditUser(val) {
      if (val === false) {
        this.editedUserIndex = -1
        this.editedUser = this.defaultUser
      }

      return val
    },
  },
  mounted() {
    // Check for local login session before using the app
    if (localStorage && localStorage.getItem('logged')) {
      this.$http
        .get('/api/info')
        .then(response => {
          if (response) {
            // console.log(response.data)
            if (response.data.admin === true) {
              this.adminUser = true
              this.engineeringModOn = true
            }
            this.getStreams()
            this.newStreamType = this.streamTypesList[0].toString()
          }
        })
        .catch(err => console.log(err))
    } else {
      this.$router.push({ name: 'login', path: '/login' })
    }

    // Stats update for ReSteams live/bitrate
    this.$nextTick(() => {
      window.setInterval(() => {
        if (this.BodyTitle.id !== 0) {
          if (this.publishPlatforms.length >= 1) {
            this.publishPlatforms.forEach(element => {
              if (element.status) {
                this.$http
                  .get(`api/restream/${element.uuid}/stats`)
                  .then(res => {
                    if (this.publishPlatforms) {
                      // console.log(res.data)
                      try {
                        this.publishPlatforms[this.publishPlatforms.indexOf(element)].stats = res.data.data
                      } catch (error) {
                        //  ignore this
                      }
                    }
                  })
                  .catch()
              }
            })
          }
        }
        this.$forceUpdate()
      }, 1000)
    })
  },

  created() {},
  methods: {
    // Create new stream and update the streams list after success
    createNewStream(streamName, streamType) {
      this.$http
        .post('api/streams/create', { name: streamName, type: streamType })
        .then(res => {
          if (res) {
            this.addNew = false
            this.newStreamName = ''
            this.newStreamType = this.streamTypesList[0].toString()
            setTimeout(() => {
              this.getStreams()
              this.getDetials(res.data.data.uuid, res.data.data.name)
            }, 1000)
          }
        })
        .catch(err => {
          this.snackMessage = err.response.data.msg
          this.snackbar = true

          // console.log(err)
        })
    },

    // ReStreams paramteres autofill for named ReStreams
    fillReStreamParams() {
      this.editedItem.reStreamUrl = this.selectedReStreamPlatform.arguments.host
      this.editedItem.app = this.selectedReStreamPlatform.arguments.app
      this.editedItem.rtmps = this.selectedReStreamPlatform.arguments.rtmps
      this.editedItem.port = this.selectedReStreamPlatform.arguments.port
    },

    // List streams list from the server based logged user
    getStreams() {
      this.streams = []
      this.$http
        .get('api/streams')
        .then(res => {
          // console.log(res.data.data)
          res.data.data.forEach(element => {
            if (this.streams.indexOf({ id: element.uuid, name: element.name }) === -1) {
              this.streams.push({ id: element.uuid, name: element.name })
            }
          })
        })
        .catch(err => {
          this.snackMessage = err.response.data.msg
          this.snackbar = true

          // console.log(err)
        })
    },

    // Logout from app and clear login session
    logOut() {
      localStorage.clear()
      this.$router.push({ name: 'login', path: '/login' })
    },

    // Get stream detials
    getDetials(id, name) {
      if (id === 0) {
        const x = document.querySelector('.v-item--active')
        if (x) {
          x.classList.remove('v-item--active')
          x.classList.remove('v-list-item--active')
        }
      }
      this.BodyTitle.name = name
      this.BodyTitle.id = id
      this.$http
        .get(`api/stream/${id}`)
        .then(res => {
          // console.log(res.data.data)
          this.BodyTitle.serverURL = `${res.data.data.serverUrl}/`
          this.BodyTitle.streamKey = res.data.data.uuid
          this.sourceLivePreviewURL = res.data.data.hlsUrl
          this.publishPlatforms = res.data.data.restreams
          this.publishPlatforms.forEach(reStream => {
            this.$http
              .get(`api/restream/${reStream.uuid}/stats`)
              .then(resReStream => {
                // this.publishPlatforms.indexOf(reStream).stats = resReStream.data.data
                Object.defineProperty(this.publishPlatforms[this.publishPlatforms.indexOf(reStream)], 'stats', {
                  value: resReStream.data.data,
                  writable: true,
                  enumerable: true,
                  configurable: true,
                })

                // Object.defineProperty(this.publishPlatforms[this.publishPlatforms.indexOf(reStream)], 'retries', {
                //   value: 0,
                //   writable: true,
                //   enumerable: true,
                //   configurable: true,
                // })
              })
              .catch(err => {
                this.snackMessage = err.response.data.msg
                this.snackbar = true

                // console.log(err)
              })
          })
          this.sourceLivePreview(this.sourceLivePreviewURL)
        })
        .catch(err => {
          this.snackMessage = err.response.data.msg
          this.snackbar = true

          // console.log(err)
        })
    },

    // Remove stream and refresh streams list
    deleteStream(streamUUID) {
      this.$http
        .get(`api/stream/${streamUUID}/delete`)
        .then(res => {
          if (res) {
            // console.log(res.data)
            this.videoOptions.sources[0].src = ''
            this.getStreams()
            this.getDetials(0, 'No Stream Selected!')
            this.leftMenuActive = -1
            this.dialogDeleteStream = false

            // this.$router.go()
          }
        })
        .catch(err => {
          this.snackMessage = err.response.data.msg
          this.snackbar = true

          // console.log(err)
        })
    },

    // stream playback called inside GetDetials()
    sourceLivePreview(url) {
      // production URL
      const baseURL = `${window.location.protocol}//${window.location.host}`

      // dev URL
      // const baseURL = 'https://nimblemux.vmclouds.co.uk'
      const stream = baseURL + url

      this.videoOptions.sources[0].src = stream

      // this.videoOptions.sources.push({
      //   src: stream,
      //   type: 'application/x-mpegURL',
      // })

      // console.log('live should play with new soruce', this.videoOptions.sources[0].src)
      this.$forceUpdate()
    },

    // table item "ReStream" edit dailog open, not used
    editItem(item) {
      this.editedIndex = this.publishPlatforms.indexOf(item)
      this.editedItem = { ...item }
      this.dialog = true
    },

    // table item "ReStream" show details dailog open
    showItem(item) {
      this.editedIndex = this.publishPlatforms.indexOf(item)
      this.editedItem = { ...item }
      this.dialogShow = true
    },

    // table item "ReStream" delete dailog open
    deleteItem(item) {
      this.editedIndex = this.publishPlatforms.indexOf(item)
      this.editedItem = { ...item }
      this.dialogDelete = true
    },

    // ReStream dialog confirm
    deleteItemConfirm() {
      // this.publishPlatforms.splice(this.editedIndex, 1)
      const refreshID = this.editedItem.stream
      this.$http
        .get(`api/restream/${this.editedItem.uuid}/delete`)
        .then(res => {
          if (res) {
            // console.log(res.data)
            this.getDetials(refreshID)
          }
        })
        .catch(err => {
          this.snackMessage = err.response.data.msg
          this.snackbar = true

          // console.log(err)
        })
      this.closeDelete()
    },

    // Edit item "ReStream" dialog close
    close() {
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = { ...this.defaultItem }
        this.editedIndex = -1
        this.selectedReStreamPlatform = { name: '' }
        this.customStreamURL = ''
      })
    },

    // Delete item "ReStream" dialog close
    closeDelete() {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = { ...this.defaultItem }
        this.editedIndex = -1
        this.customStreamURL = ''
      })
    },

    // Delete stream dialog close
    closeDeleteStream() {
      this.dialogDeleteStream = false
    },

    // Make ReStream
    save() {
      if (this.editedIndex > -1) {
        // maybe can edit
        Object.assign(this.publishPlatforms[this.editedIndex], this.editedItem)
      } else {
        // add new publish platfrom
        console.log(this.editedItem)
        this.$http
          .post('api/restreams/create', {
            name: this.editedItem.name,
            stream: this.BodyTitle.id,
            type: 'rtmp',
            app: this.editedItem.app,
            key: this.editedItem.reStreamKey,
            host: this.editedItem.reStreamUrl,
            port: this.editedItem.port,
            rtmps: this.editedItem.rtmps,
          })
          .then(res => {
            if (res) {
              // console.log(res.data.data)
              this.getDetials(this.BodyTitle.id)
            }
          })
          .catch(err => {
            this.snackMessage = err.response.data.msg
            this.snackbar = true

            // console.log(err)
          })

        // this.publishPlatforms.push(this.editedItem)
      }
      this.close()
    },

    // Start/Stop ReStream
    isLive(item) {
      if (item.status === true) {
        this.$http
          .get(`api/restream/${item.uuid}/start`)
          .then(res => {
            if (res) {
              // console.log(res.data)
              // this.getDetials(item.stream)
            }
          })
          .catch(err => {
            this.snackMessage = err.response.data.msg
            this.snackbar = true

            // console.log(err)
          })

        // console.log('this live stream just turned to: True')
      } else {
        this.$http
          .get(`api/restream/${item.uuid}/stop`)
          .then(res => {
            if (res) {
              // console.log(res.data)
              // this.getDetials(item.stream)
            }
          })
          .catch(err => {
            this.snackMessage = err.response.data.msg
            this.snackbar = true

            // console.log(err)
          })

        // console.log('this live stream just turned to: False')
      }
    },
    engineeringActivate() {
      setTimeout(() => {
        this.engineeringModOn = true
        this.engineeringModDialog = false
      }, 2000)
    },
    fillEditedItem() {
      // eslint-disable-next-line no-useless-escape
      const regex = new RegExp(/(rtmp)(s?):\/\/([\w\d\-\_\.]+):?(\d+)?\/([\w\d\-\_\.]+)(?:\/([\w\d\-\_\.]+))?/)
      let temp = this.customStreamURL
      temp = temp.split(regex)
      if (temp[2] === 's') {
        this.editedItem.rtmps = true
      } else {
        this.editedItem.rtmps = false
      }
      this.editedItem.reStreamUrl = temp[3].toString()
      if (temp[4] !== undefined) {
        this.editedItem.port = temp[4].toString()
      } else if (temp[2] === 's') {
        this.editedItem.port = '443'
      } else {
        this.editedItem.port = '1935'
      }
      this.editedItem.app = temp[5].toString()
      if (temp[6] !== undefined) {
        this.editedItem.reStreamKey = temp[6].toString()
        this.customStreamURL = `${temp[1] + temp[2]}://${temp[3]}:${temp[4]}/${temp[5]}/`
      } else {
        this.editedItem.reStreamKey = ''
      }

      // console.log(this.editedItem)
    },
    getUsers() {
      this.$http
        .get('api/users')
        .then(async res => {
          if (res) {
            // console.log(res.data)
            const temp = res.data.data
            this.usersList = temp.map(obj => ({ username: obj, password: '123456', showPass: false }))
            this.usersTableLoading = false

            // the following part would get all users data before filling the table & stopped for now
            // const temp = res.data.data
            // // map through the repo list
            // const promises = temp.map(async user => {
            //   // request details from GitHub’s API with Axios
            //   const response = await this.showUser(user)

            //   return response
            // })

            // // wait until all promises resolve
            // await Promise.all(promises).then(results => {
            //   results.map(obj => ({ ...obj, showPass: 'false' }))
            //   this.usersList = [...results]
            //   this.usersTableLoading = false

            //   // console.log(results)
            // })
          }
        })
        .catch(err => {
          this.snackMessage = err.response.data.msg
          this.snackbar = true

          // console.log(err)
        })
    },
    showUser(item) {
      if (item.showPass === false) {
        this.$http
          .get(`/api/user/${item.username}`)
          .then(res => {
            const index = this.usersList.findIndex(user => user.username === item.username)
            this.usersList[index].password = res.data.data.password
            this.usersList[index].showPass = true
          })
          .catch(err => {
            this.snackMessage = err.response.data.msg
            this.snackbar = true

            // console.log(err)
          })
      } else {
        const index = this.usersList.findIndex(user => user.username === item.username)
        this.usersList[index].showPass = false
        this.usersList[index].password = '123456'
      }

      // this verion work with getUsers() when it fill the whole table first time
      // return this.$http
      //   .get(`/api/user/${item}`)
      //   .then(res => res.data.data)
      //   .catch(err => {
      //     this.snackMessage = err.response.data.msg
      //     this.snackbar = true

      //     // console.log(err)
      //   })
    },
    saveUser() {
      if (this.editedUserIndex > -1) {
        // maybe can edit
        this.$http
          .post(`/api/user/${this.editedUser.username}/password`, { password: this.editedUser.password })
          .then(res => {
            if (res) {
              // console.log(res.data.data)
              this.getUsers()
              this.editedUserIndex = -1
              this.editedUser = this.defaultUser
              this.dialogCreateEditUser = false
            }
          })
          .catch(err => {
            this.snackMessage = err.response.data.msg
            this.snackbar = true

            // console.log(err)
          })
      } else {
        // add new publish platfrom
        this.$http
          .post('/api/users/create', { username: this.editedUser.username, password: this.editedUser.password })
          .then(res => {
            if (res) {
              // console.log(res.data.data)
              this.getUsers()
              this.editedUserIndex = -1
              this.editedUser = this.defaultUser
              this.dialogCreateEditUser = false
            }
          })
          .catch(err => {
            this.snackMessage = err.response.data.msg
            this.snackbar = true

            // console.log(err)
          })
      }
    },
    deleteUser(item) {
      this.$http
        .delete(`/api/user/${item.username}/delete`)
        .then(res => {
          if (res) {
            this.getUsers()
          }
        })
        .catch(err => {
          this.snackMessage = err.response.data.msg
          this.snackbar = true

          // console.log(err)
        })
    },
    editUser(item) {
      this.editedUserIndex = this.usersList.indexOf(item)
      this.editedUser = { ...item }
      this.dialogCreateEditUser = true

      // console.log(item)
    },
  },
}
</script>
<style scoped>
#skullIcon:hover {
  color: red !important;
}
</style>
