<template>
  <div id="vidC" class="d-flex justify-center">
    <video id="vidP" ref="videoPlayer" class="video-js"></video>
  </div>
</template>

<script>
import 'video.js/dist/video-js.min.css'
import videojs from 'video.js'

export default {
  name: 'VideoPlayer',
  props: {
    options: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  data() {
    return {
      player: null,
      errorCount: 0,
    }
  },
  mounted() {
    try {
      this.player = videojs(this.$refs.videoPlayer, this.options, () => {
        // this.player.log('onPlayerReady', this)
        const playerTech = this.player.tech({ IWillNotUseThisInPlugins: true })
        playerTech.on('retryplaylist', e => {
          if (e) {
            this.errorCount += 1
            if (this.errorCount === 1) {
              setTimeout(() => {
                this.player.src({
                  src: `${this.options.sources[0].src}`,
                  type: 'application/x-mpegURL',
                })
                this.$forceUpdate()
                this.errorCount = 0
                console.log('Catched Issue Refreshing in 6 seconds!')
              }, 8000)
            }
          }
          console.log(e)
        })

        // console.log(this.player.tech())
      })
    } catch (error) {
      console.log('Error event fired!')
      this.player.log('error catched: ', error)
    }
    this.player.on('error', () => {
      console.log('Error event fired!')
      console.log('The following error occurred:')
    })
    this.player.on('retryplaylist', () => {
      console.log('retryplaylist fired!')
    })

    // this.player.on('ready', () => {
    //   console.log('Error event fired!')
    // })

    setInterval(() => {
      try {
        if (this.player.readyState() === 0) {
          this.player.src({
            src: `${this.options.sources[0].src}`,
            type: 'application/x-mpegURL',
          })
          this.$forceUpdate()

          // this.player.load()
          // this.player.log('trying to reload source', this.player.readyState())
        }
      } catch (error) {
        // this.player.log('error catched: ', error)
      }
    }, 10000)
    setInterval(() => {
      if (this.player.error()) {
        this.player.src({
          src: `${this.options.sources[0].src}`,
          type: 'application/x-mpegURL',
        })
        this.$forceUpdate()
        console.log('error occured', this.player.error())
        if (this.player.errpr().message === 'No compatible source was found for this media.') {
          this.player.dispose()
        }
      }
    }, 1000)
  },
  beforeDestroy() {
    if (this.player) {
      this.player.dispose()
    }
  },
}
</script>
<style scoped>
#vidC {
  width: 100%;
  height: 56.25%;
  aspect-ratio: 16 / 9 !important;
}
#vidP {
  width: 96%;
  height: 96%;
  aspect-ratio: 16 / 9 !important;
}
</style>
